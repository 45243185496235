import React from "react"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import TripleColumnBlock from "../components/tripleColumnBlock"
import PageWrapper from "../components/pageWrapper"

import ActiveItemGallery from "@components/ActiveItemGallery"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  DownloadProductBrochureButton,
} from "../components/buttons"

const galleryItems = [
  {
    key: 1,
    title: "Euro Seedy Glass",
    images: ["/images/pattern-glass/01-seedy.jpg"],
    description:
      "​Euro Seedy Glass is similar to the classic seeded pattern glass, but with a slightly elongated seed pattern that is randomly and even dispersed throughout. The soft finish offers a minor, not aggressive, textured appearance compared to clear glass. Great for low light applications where some pattern is desired.",
    thickness: "1/8",
    factory_size: "36 X 72",
    stock_size: "36 X 72",
  },
  {
    key: 2,
    title: `Thin Reed Glass (1/8” Reeds)`,
    images: ["/images/pattern-glass/02-thin-reed.jpg"],
    description: `With 1/8” Reed spacing the sleek, sophisticated, and refined lines of Thin Reed glass are perfect transitional style for creating subtle distortions for limited obscurity, and yet give an interesting look for every style from traditional to modern.`,
    thickness: "5/32",
    factory_size: "65 x 85",
    stock_size: "32 1/2 x 85",
  },
  {
    key: 3,
    title: "Sereemy Glass",
    images: ["/images/pattern-glass/03-sereemy.jpg"],
    description:
      "​This soft and versatile pattern of smooth, flowing waves and small bubbles imparts a low to moderate degree of obscurity. The texture really imparts personality to the glass. It suggests dimension, depth and contrast. In clear glass texture is useful for achieving varying degrees of privacy, while adding elegance and richness.",
    thickness: "5/32",
    factory_size: "65 x 85",
    stock_size: "32 1/2 x 85",
  },
  {
    key: 4,
    title: "Frosted Glass",
    images: ["/images/pattern-glass/04-frosted.jpg"],
    description:
      "​Frosted glass is a pearlescent glass that delivers a pure and simple design aesthetic with low transparency and high privacy. The pattern maintains a pitted surface on one side and has the effect of rendering the glass translucent by scattering the light which passes through, thus blurring images while still transmitting light.",
    thickness: "1/8 and 5/32",
    factory_size: "63 x 88 1/2",
    stock_size: "31 1/2 x 88 1/2",
  },
  {
    key: 5,
    title: "Reed Glass",
    images: ["/images/pattern-glass/05-half-inch-reed.jpg"],
    description:
      "​Reed glass, also known as fluted or ribbed glass, has a wider 1/2” spacing compared to our Thin Reed Glass. The clean lines of Reed glass bring a modern look to the home, featuring a vertical, linear pattern. Reed glass provides ample natural light while maintaining moderate privacy with skewed images behind glass.",
    thickness: "5/32, 1/4, and 3/8",
    factory_size: "63 x 84 and 72 x 96",
    stock_size: "31 1/2 x 88 1/2 and 36 x 96",
  },
  {
    key: 6,
    title: "Euro Hammered Glass",
    images: ["/images/pattern-glass/06-hammered.jpg"],
    description:
      "​Inspired by hammered metal surfaces, Euro Hammered glass has a dimpled surface that gives a stylish accent to windows and doors while adding privacy. In addition to its artistic beauty, Euro Hammered glass tends to be inviting and aesthetically versatile.",
    thickness: "1/8",
    factory_size: "60 x 53",
    stock_size: "60 x 53",
  },
  {
    key: 7,
    title: "Antique Glass",
    images: ["/images/pattern-glass/07-antique.jpg"],
    description:
      "​Antique pattern glass is a rich tradition that originated years ago. The glass features slight wavy lines, an indirect light source distorts the view of those who look at it. This adds authenticity and gives each beautiful piece uniqueness.",
    thickness: "1/8",
    factory_size: "39 3/4 x 59",
    stock_size: "39 3/4 x 59",
  },
  {
    key: 8,
    title: "Baroque Glass",
    images: ["/images/pattern-glass/09-baroque.jpg"],
    description: `Dynamic and artful, it's the modern version of the hand-blown "Reemy" antique glass. With high transparency, Baroque creates a "conflict" that manifests in a dimensional swirling texture.`,
    thickness: "1/8",
    factory_size: "48 x 60",
    stock_size: "48 x 60",
  },
  {
    key: 9,
    title: "Bubbles Glass",
    images: ["/images/pattern-glass/10-bubbles.jpg"],
    description:
      "​For those who require a more subtle yet still interesting pattern, our Bubbles pattern glass may be your perfect solution. Suggestive of water drops on a glass make this an excellent choice for anyone who wants something different but with some class.",
    thickness: "3/16",
    factory_size: "72 x 84",
    stock_size: "36 x 84",
  },
  {
    key: 10,
    title: "Chinchilla Glass",
    images: ["/images/pattern-glass/11-chinchilla.jpg"],
    description:
      "​The Chinchilla pattern glass is a free-flowing configuration of gentle lines and curves programmed for desired views, giving privacy when needed with absolutely no interruption to your everyday life.",
    thickness: "5/32",
    factory_size: "63 x 84",
    stock_size: "31 1/2 x 84",
  },
  {
    key: 11,
    title: "Softening Euro Cord Glass",
    images: ["/images/pattern-glass/25-cord.jpg"],
    description:
      "​​This vertical pattern is reminiscent of waves on water. A one-of-a-kind pattern on this glass will instantly transform your design.",
    thickness: "1/8",
    factory_size: "72 X 84",
    stock_size: "36 X 84",
    directional_pattern: "Yes",
  },
  {
    key: 12,
    title: "Delta Frost Glass",
    images: ["/images/pattern-glass/13-delta-frost.jpg"],
    description:
      "​The delta frost pattern is a stunning attraction. The frost and running water pattern create an elegant and free flowing design that immerses our senses with its beauty.",
    thickness: "5/32",
    factory_size: "63 x 84",
    stock_size: "31 1/2 x 84",
  },
  {
    key: 13,
    title: "Dense Seedy Glass",
    images: ["/images/pattern-glass/14-dense-seedy.jpg"],
    description:
      "​Dense Seedy glass is a textured and wavy form of glass that represents the appearance of small, wavy bubbles. The effect in its seeding appearance makes for an intriguing decorative item with appeal to architects, enthusiasts of architecture, and others.",
    thickness: "1/8",
    factory_size: "32 x 84 1/2",
    stock_size: "32 x 84 1/2",
  },
  {
    key: 14,
    title: "English Flemish Glass",
    images: ["/images/pattern-glass/15-english-fleming.jpg"],
    description:
      "​Enjoy the look of hand-blown glass without the seeds and bubbles. The look with this intricate detail that is reminiscent of hand-blown glass for a slight distortion.",
    thickness: "5/32",
    factory_size: "52 x 84",
    stock_size: "52 x 84",
  },
  {
    key: 15,
    title: "Glue Chip Glass",
    images: ["/images/pattern-glass/16-glue-chip.jpg"],
    description:
      "​Glue Chip decorative pattern glass adds a distinctive element to any space. The attractive random patterns that gives the look of ice crystals that maintain a sharp, crisp appearance old and new applications.",
    thickness: "1/8",
    factory_size: "30 x 84",
    stock_size: "30 x 84",
  },
  {
    key: 16,
    title: "Ice Crystal Glass",
    images: ["/images/pattern-glass/17-ice-crystal.jpg"],
    description:
      "​Ice Crystal textured glass can add incredible depth and beauty. Embedded crystal patterns on one side create texture that is truly captivating in its effect.",
    thickness: "5/32",
    factory_size: "65 x 85",
    stock_size: "32 1/2 x 85",
  },
  {
    key: 17,
    title: "Raised Bamboo Glass",
    images: ["/images/pattern-glass/21-raised-bamboo.jpg"],
    description:
      "​This rugged bamboo stock lends a delightful touch of style to any room. This Bamboo patterned glass is most often used in cascading water features to add character and coolness.",
    thickness: "3/16",
    factory_size: "63 x 84",
    stock_size: "31 1/2 x 84",
  },
  {
    key: 18,
    title: "Taffeta Glass",
    images: ["/images/pattern-glass/22-taffeta.jpg"],
    description:
      "​Designers and consumers alike love this one-of-a-kind elegant style. Modern lines create a fluid, organic, floral swirling shape giving a slight, pleasant, beautiful origin.",
    thickness: "5/32",
    factory_size: "72 x 84",
    stock_size: "36 x 84",
  },
  {
    key: 19,
    title: "Vecchio Glass",
    images: ["/images/pattern-glass/23-vecchio.jpg"],
    description:
      "​Caught in the struggle between hard and soft? Vecchio Glass gives you a push in the opposite direction. With miniscule seeds throughout, alongside a slightly flattened granite texture on one side, this fabrication provides for light diffusing glass with visual depth.",
    thickness: "1/8",
    factory_size: "39 3/8 x 61",
    stock_size: "39 3/8 x 61",
  },
  {
    key: 20,
    title: "Cotswold Glass",
    images: ["/images/pattern-glass/cotswold.jpg"],
    description:
      "​The Cotswold Glass pattern is vibrant and enchanting, much like the aesthetic of rain cascading down a window. The patterned raindrops are slightly more elongated with a shallow flow, inviting tranquil vibes into any breathing spaces.",
    thickness: "5/32",
    factory_size: "65 x 85",
    stock_size: "32 1/2 x 85",
  },
]

const PatternGlass = () => {
  return (
    <PageLayout
      heroTitle="OBSCURE TEXTURED PATTERN GLASS"
      heroBackgroundImage="/images/pattern-glass/header-obscure-textured-pattern-glass.jpg"
    >
      <SEO
        title="Obscure Textured Pattern Glass | ArtVue Glass"
        ogDescription="ArtVue is a leading custom glass supplier for architects and designers looking to enhance the look, feel and functionality of their projects. Pattern glass enhances the privacy, beauty, and visibility of restaurants, offices, homes and other custom environments."
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>
              Provide Distinctive Designs That add Visual Interest and Different
              Opacity Levels to any Space
            </h2>
            <p>
              Pattern glass is used in nearly any application where a clear
              piece of glass is currently found and where added privacy and
              obscurity is needed without blocking light. Pattern glass enhances
              the privacy, beauty, and visibility of restaurants, offices, homes
              and other custom environments.
            </p>
            <p>
              ArtVue Glass is distinguished by not only provided industry
              leading pattern glass, but also by combining different pattern
              glass with other decorative glass options, such as back painting,
              decorative interlayers, and laminated.
            </p>
          </div>
        }
      />
      <PageWrapper color="grey">
        <ActiveItemGallery
          items={galleryItems}
          galleryTitle="Some of our most popular clear textured pattern glass include:"
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <p>
                <i>
                  All thicknesses and sizes in inches. Actual glass may vary
                  slightly from the image shown. Stock size may vary.
                </i>
              </p>
              <p>
                            <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "calendar",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
              </p>
            </div>
          }
        />
      </PageWrapper>
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>Features and Benefit of Obscure Textured Pattern Glass</h2>
              <ul>
                <li>Make genuinely unique one-of-a-kind designs</li>
                <li>Customize a look to match existing decorating themes</li>
                <li>
                  Remain fully functional and durable, just like clear glass
                </li>
                <li>Durable design to meet your specifications</li>
              </ul>
              <p>
                Different glass configurations are also available — annealed,
                tempered, and laminated — to make a genuinely unique design fit
                for any solution.
              </p>
                          <FormWrapper
              modal
              buttonProps={{
                content: "Contact Us Today",
                size: "tiny",
                icon: "phone",
              }}
            >
              <ContactForm />
            </FormWrapper>
            </div>
          }
          textRight={
            <div>
              <h2>Maintaining Patterned Glass</h2>
              <p>
                With the latest methods and the most reliable materials to
                curate patterned glass, you don't need to worry about
                maintaining that classy look too much.
              </p>
              <p>
                Use glass cleaner and any household non-abrasive items and have
                your glass sparkling all the time.
              </p>
              <p>
                Privacy, visibility, luxury, and aesthetics, ArtVue’s obscure
                glass offers an extensive range of benefits.
              </p>
            </div>
          }
        />
      </div>
      <PageWrapper color="grey">
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>How Is Obscure Glass Made?</h2>
              <p>
                ArtVue Glass prides itself on high-quality obscure glass
                creation, personalized design, and quality that lasts.
              </p>
              <p>
                Obscure glass is created by taking two counter-rotating
                cylinders and passing partially molten float glass beneath them.
                The upper cylinder imprints a pattern on the top surface of the
                glass, while the lower cylinder keeps the back surface smooth.
              </p>
              <p>
                To prevent any cracking, the glass is slowly cooled down in a
                process called annealing to relieve internal residual stresses
                introduced during manufacturing.
              </p>
              <p>
                Since obscure glass contains a pattern on one side, we are able
                to laminate our obscure glass with other products – namely
                mirror, safety and structural interlayers and back painted glass
                – for a truly unique look.
              </p>
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>Applications of Obscure Patterned Glass</h2>
              <p>
                Easy to design and featuring seamless installation, obscure
                textured pattern glass can be used wherever you wish to create a
                modern vibe or make an impression that lasts.
              </p>
              <p>
                It can be installed in commercial or residential places alike.
              </p>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h4>Commercial</h4>
              <ul>
                <li>Doors</li>
                <li>Railings</li>
                <li>Shopfronts</li>
                <li>Displays</li>
                <li>Conference Rooms</li>
                <li>Wall Partitions</li>
              </ul>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/pattern-glass/office-chairs-behind glass-wall-obscure-textured-pattern-glass.jpg"
                alt="Commercial Laminated Glass"
              />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h4>Residential</h4>
              <ul>
                <li>Shower Doors and Enclosures</li>
                <li>Glass Tabletops</li>
                <li>Separators</li>
                <li>Kitchen Cabinet Door Inserts</li>
                <li>Shelving</li>
                <li>Windows</li>
                <li>Home Office Doors</li>
                <li>Basement Bedroom Doors</li>
                <li>Kitchen Backsplash</li>
              </ul>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/pattern-glass/kitchen-cabinet-doors-obscure-textured-pattern-glass.jpg"
                alt="Residential Laminated Glass"
              />
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <p>
                Breathe new life into your interiors with aesthetic,
                one-of-a-kind, personalized designs that resonate with your
                identity and caters to your needs.
              </p>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>
                The Levels of Obscurity In Obscure Textured, Patterned Glass
              </h2>
              <p>
                The prime purpose of obscure glass is to offer you the privacy
                you want while still allowing natural light to be transmitted.
              </p>
              <p>
                You can choose between the levels or let us know what kind of
                obscurity you desire.
              </p>
              <p>
                Do you want to see partially behind the glass or wish for a
                completely hidden view? Whatever the case, we have you covered.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/pattern-glass/privacy-door-obscure-textured-pattern-glass.jpg"
                alt="Obscure Textured Patterned Glass"
              />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>
                Enjoy The Array Of Benefits Of Natural Light. Embrace Obscure
                Glass!
              </h2>
              <p>
                Adding obscure, patterned glass to your home comes with an added
                benefit – impeccable light diffusion.
              </p>
              <p>
                Instead of going completely sheer with transparent glass, or
                entirely hidden with an opaque one, choose to go the
                mid-transparency obscure way and let natural light flow through
                your home, seamlessly.
              </p>
              <p>
                Way better than artificial lighting, natural light enhances the
                quality of your life and offers you an extensive range of
                benefits, including:
              </p>
              <p>
                &#10003; Lower Electricity Costs
                <br />
                &#10003; Reduced Eyestrain
                <br />
                &#10003; Healthier Skin
                <br />
                &#10003; Well-regulated Sleep Pattern
                <br />
                &#10003; Enhanced Focus and Productivity
                <br />
                &#10003; High psychological well-being
                <br />
              </p>
              <p>
                Wake up to beautiful streaks of sunlight peering through the
                glass and begin your day with a smile on your face.
              </p>
              <p>
                ArtVue Glass cares about you and your health and gives you
                nothing less than the best.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/pattern-glass/modern-loft-obscure-textured-patterned-glass.jpg"
                alt="Benefits Of Natural Light"
              />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>Frosted And Opaque Obscure Glass</h2>
              <p>
                Glass type, design, and area of installation play a large role
                in deciding which obscure glass to purchase. But do not be
                confused, because you have the experts at ArtVue ready to
                provide assistance.
              </p>
              <p>
                Frosted glass is a type of obscure glass with a highly
                translucent surface that emits less light and primarily hides
                view so that only fuzzy silhouettes of the other behind the
                glass are visible.
              </p>
              <p>
                When searching for the right material for shower doors, washroom
                windows, or anywhere you wish to add the touch of glass without
                harming privacy – frosted obscure glass is the way to go.
              </p>
              <p>
                In contrast, opaque obscure glass is of the highest obscurity
                level and is installed in displays, washrooms, and meeting
                rooms, among other places.
              </p>
              <p>
                Make maintaining privacy a convenience without compromising on
                light and visibility! Embrace frosted and opaque Obscure Glass.
              </p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/pattern-glass/bedroom-partition-obscure-textured-patterned-glass.jpg"
                alt="Frosted And Opaque Obscure Glass"
              />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>Countless Design Choices For Obscure Glass</h2>
              <p>
                Obscure glass and customization – you can tune your property’s
                interior according to your design preferences.
              </p>
              <p>
                Plain, frosted obscure glass offers simplicity to your private
                spaces. With acid-etched and sandblasted frosted options,
                maintain light and visibility to your liking. Ribbed glass adds
                texture to interiors and offers even more control over
                visibility and privacy.
              </p>
              <p>
                From flower patterns to geometric designs, patterned glass turns
                any ordinary space into a place beaming with class and luxury.
                You can even opt for bubbled obscure glass to uplift the
                ambiance in your restaurant or provide showers and bathrooms a
                nature-like feel.
              </p>
              <p>
                At ArtVue, we design and create custom obscure glasses to
                transform your place’s outlook and optimize privacy.
              </p>
            </div>
          }
          textRight={
            <div>
              <h2>Patterned Glass: Modern, Customizable & Durable</h2>
              <p>
                If you want to stay on top of interior trends but opt for
                personalized glass designs that are bound to last, patterned
                glass is the go-to option.
              </p>
              <p>
                Whether you hire an interior designer, look up patterns online,
                or choose to go completely unique, you simply can’t go wrong.
              </p>
              <p>
                You’ll find leaves, Flemish, and tiny squares are some of the
                common patterns for glass. At ArtVue, you can even point us to
                patterns that resonate with you or complement your overall
                home’s feel.
              </p>
              <p>
                With every design, you can maneuver the level of obscurity and
                privacy to your needs. From bathroom windows and shower screens
                to patio doors and privacy walls, any residential or commercial
                space can elevate its aesthetic appeal with custom patterned
                glass.
              </p>
              <p>Make your home or office stand out!</p>
            </div>
          }
        />
        <div className="triple-imgs">
          {[
            {
              url:
                "/images/pattern-glass/thin-reed-obscure-textured-patterned-glass.jpg",
              alt: "Thin Reed",
            },
            {
              url:
                "/images/pattern-glass/bubbles-obscure-textured-patterned-glass.jpg",
              alt: "Bubbles Obscure",
            },
            {
              url:
                "/images/pattern-glass/reeded-obscure-textured-patterned-glass.jpg",
              alt: "Reeded Obscure",
            },
          ].map((img, i) => (
            <img src={img.url} key={i} alt={img.alt} />
          ))}
        </div>
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>Investment In Privacy & Aesthetics</h2>
              <p>Clear glass is cheaper than patterned glass.</p>
              <p>
                But the level of privacy, security, and design that textured
                glass promises are unmatched. At ArtVue, we make sure to cover
                every aspect of the glass to tailor it to your requirements
                while providing affordable all-round solutions.
              </p>
              <p>
                Consider patterned obscure glass an investment for your home or
                business that continues paying dividends for years to come.
              </p>
              <p>Invest in comfort and convenience!</p>
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>What Makes Obscure Glass Special?</h2>
              <p>
                Combining modern architecture with functionality, obscure glass
                is just as its name beholds – a glass that is obscure. This
                brings forth the idea of privacy through added design, texture,
                and geometric shapes.
              </p>
              <p>
                Play with the levels of opacity to create an environment as
                intimate or transparent as you want. Promoting privacy,
                security, and modern aesthetics, obscure glass is a way to amp
                up your interiors without going out of your way or budget, and
                ArtVue can help with that.
              </p>
              <p>Reach out to us for a customized quote!</p>
                          <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "calendar",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
            </div>
          }
        />
      </PageWrapper>
    </PageLayout>
  )
}

export default PatternGlass
